/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CatalogueContext } from 'src/context/CatalogueContextProvider'
import { EventContext } from 'src/context/EventContextProvider'
import { EventGroupContext } from 'src/context/EventGroupContextProvider'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IEvent from 'src/structures/Interfaces/IEvent'

import useEventGroupBaskets from './useEventGroupBaskets'
import { useServiceFees } from './useServiceFees'

export const useBasketCalculations = () => {
    const { basket } = useSelector((state) => state) as any
    const { getProductById } = useContext(CatalogueContext) as any
    const { event } = useContext(EventContext) as any
    const { eventGroup } = useContext(EventGroupContext) as any

    const { loyaltyContact } = useContext(LoyaltyContext)
    const availableLoyaltyPoints = useMemo(
        () => Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10),
        [loyaltyContact?.loyalty_balance]
    )

    const basketsForOrderGroup = useEventGroupBaskets()

    const { getApplicableFees } = useServiceFees()

    const calculateMaxOrderableAmount = useCallback(
        (eventId: string | null, product: any) => {
            // try to find product in catalogue, fallback to given product (eg. when in eventgroup and catalogue is not available)
            const productFromCatalogue = getProductById(product.originalId ?? product.id) ?? product

            if (isNotNullOrUndefined(productFromCatalogue?.stock) && isNotNullOrUndefined(eventId)) {
                const basketProducts = basket[eventId]?.products ?? {}
                const filteredProducts = []

                for (const key in basketProducts) {
                    if (
                        basketProducts.hasOwnProperty(key) === true &&
                        basketProducts[key].originalId === (productFromCatalogue.originalId ?? productFromCatalogue.id)
                    ) {
                        filteredProducts.push(basketProducts[key])
                    }
                }

                return Math.max(
                    product.stock -
                        filteredProducts.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
                    0
                )
            }
            return Number.MAX_SAFE_INTEGER
        },
        [getProductById]
    )

    const calculateTotalBasketGroupPrice = useCallback(
        (eventGroupId: string, includeTips = true, includeFees = true) => {
            if (!isNotNullOrUndefined(basketsForOrderGroup) || !isNotNullOrUndefined(eventGroup)) return 0

            let total = basketsForOrderGroup.reduce(
                (accumulator: number, item: { totalPrice: number }) => accumulator + (item?.totalPrice ?? 0),
                0
            )

            if (includeFees) {
                // eslint-disable-next-line unicorn/no-array-for-each
                eventGroup?.events?.forEach((eventGroupEvent: IEvent) => {
                    if (isNotNullOrUndefined(basket[eventGroupEvent.id])) {
                        const { totalApplicableFees } = getApplicableFees(
                            basket[eventGroupEvent.id].totalPrice,
                            eventGroupEvent.service_fees
                        )
                        total += totalApplicableFees
                    }
                })

                const { totalApplicableFees: totalApplicableGroupFees } = getApplicableFees(
                    total,
                    eventGroup.service_fees
                )
                total += totalApplicableGroupFees
            }

            if (includeTips) {
                total += basket?.tips?.[eventGroupId]?.value ?? 0
            }

            return total
        },
        [basket, basketsForOrderGroup, eventGroup]
    )

    const calculateTotalBasketPrice = useCallback(
        (eventId: string, includeTips = true, includeFees = true) => {
            if (!isNotNullOrUndefined(eventId)) return 0

            let total = basket[eventId]?.totalPrice ?? 0

            if (includeFees) {
                const { totalApplicableFees } = getApplicableFees(total, event?.service_fees ?? [])
                total += totalApplicableFees
            }

            if (includeTips) {
                total += basket?.tips?.[eventId]?.value ?? 0
            }

            return total
        },
        [basket, event]
    )

    const calculateSpendableLoyaltyPoints = useCallback(
        (input: { eventId: string | null; eventGroupId: string | null }) => {
            // no params
            if (!isNotNullOrUndefined(input?.eventId) && !isNotNullOrUndefined(input?.eventGroupId)) {
                return availableLoyaltyPoints
            }

            // eventGroup
            if (isNotNullOrUndefined(input?.eventGroupId) && isNotNullOrUndefined(basketsForOrderGroup)) {
                const basketTotal = basketsForOrderGroup.reduce(
                    (accumulator: number, item: { totalLoyaltyPrice: number }) =>
                        accumulator + (item?.totalLoyaltyPrice ?? 0),
                    0
                )

                return Math.max(availableLoyaltyPoints - basketTotal, 0)
            }

            // event
            if (isNotNullOrUndefined(input?.eventId)) {
                const basketTotal = basket[input.eventId]?.totalLoyaltyPrice ?? 0
                return Math.max(availableLoyaltyPoints - basketTotal, 0)
            }

            // fallback
            return availableLoyaltyPoints
        },
        [availableLoyaltyPoints, basket]
    )

    return {
        calculateMaxOrderableAmount,
        calculateTotalBasketGroupPrice,
        calculateTotalBasketPrice,
        calculateSpendableLoyaltyPoints,
    }
}
