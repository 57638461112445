import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import useCatalogueQuery from '../hooks/useCatalogueQuery.ts'
import usePromotions from '../hooks/usePromotions.ts'
import Promotion from '../structures/Classes/Promotion'
import GeneratedCategories from '../structures/Enums/GeneratedCategories.enum.ts'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

import { KioskContext } from './KioskContextProvider'
import { ThemeContext } from './ThemeContextProvider'

export const CatalogueContext = createContext({
    isLoading: undefined,
    isError: undefined,
    catalogue: undefined,
    activeCategoryGroup: undefined,
    activeCategory: undefined,
    categoryScrollSpyIds: undefined,
    searchQuery: undefined,
    isSearching: undefined,
    promotions: undefined,
    getCategoryGroupById: () => undefined,
    getCategoryById: () => undefined,
    getProductById: () => undefined,
    getProductByPlu: () => undefined,
    getProductsByPlu: () => undefined,
    getPromotionsForProductById: () => undefined,
})

function CatalogueContextProvider({ children }) {
    const { locale } = useSelector((state) => state)
    const { eventId, categoryGroupId, categoryId } = useParams()
    const { theme } = useContext(ThemeContext)
    const { kioskMode } = useContext(KioskContext)

    const { promotions: promotionsData } = usePromotions({ eventId })

    const {
        isLoading,
        isError,
        catalogue,
        catalogueMapped,
        allCategoryGroupsInCatalogue,
        allCategoriesInCatalogue,
        allProductsInCatalogue,
        getCategoryGroupById,
        getCategoryById,
        getProductById,
    } = useCatalogueQuery({ eventId, categoryGroupId, categoryId })

    const [catalogueData, setCatalogueData] = useState({
        ...catalogueMapped,
        activeCategoryGroup: undefined,
        activeCategory: undefined,
        categoryScrollSpyIds: undefined,
        promotions: undefined,
    })

    const [searchQuery, setSearchQuery] = useState(undefined)
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        if (isNotNullOrUndefined(catalogueMapped)) {
            setCatalogueData((prev) => ({
                ...prev,
                ...catalogueMapped,
            }))
        }
    }, [catalogueMapped])

    useEffect(() => {
        if (!isLoading && isNotNullOrUndefined(catalogueMapped) && isNotNullOrUndefined(catalogue)) {
            setCatalogueData((prev) => ({
                ...prev,
                activeCategoryGroup: catalogueMapped.categoryGroups.get(parseInt(categoryGroupId, 10)) || catalogue[0],
            }))
        }
    }, [catalogueMapped, catalogue, locale])

    useEffect(() => {
        if (isNotNullOrUndefined(categoryId) && isNotNullOrUndefined(catalogueData.categories)) {
            setCatalogueData((prev) => ({
                ...prev,
                activeCategory: catalogueData.categories.get(parseInt(categoryId, 10)),
            }))
        }
    }, [categoryId, catalogueData.categories, locale])

    useEffect(() => {
        if (isNotNullOrUndefined(catalogueData?.activeCategoryGroup)) {
            const filteredCategories = catalogueData.activeCategoryGroup.categories?.filter(
                (cat) => cat.id !== GeneratedCategories.FAVOURITES && cat.id !== GeneratedCategories.POPULAR
            )
            setCatalogueData((prev) => ({
                ...prev,
                categoryScrollSpyIds: Array.from(filteredCategories, (cat) => `category-${cat.id}`),
            }))
        }
    }, [catalogueData?.activeCategoryGroup, locale])

    useEffect(() => {
        if (catalogueData.products?.size > 0 && promotionsData?.length > 0) {
            const promos = promotionsData.reduce((acc, promo) => {
                if (kioskMode && promo.promotion?.type === 'competition') return acc

                const promoProductIds = promo.product_ids.filter((pid) => catalogueData.products.has(parseInt(pid, 10)))
                promo.product_ids = promoProductIds
                acc.push(new Promotion(promo.promotion, promoProductIds, catalogueData.products, theme))
                return acc
            }, [])

            setCatalogueData((prev) => ({ ...prev, promotions: promos }))
        }
    }, [catalogueData.products, promotionsData, theme, locale])

    const getProductByBarcode = useCallback(
        (code) => {
            const product = Array.from(catalogueData.products?.values()).find((product) =>
                product.barcodes?.some((barcode) => barcode.code === String(code))
            )

            if (!product) return null

            const barcode = product.barcodes.find((barcode) => barcode.code === String(code))

            return {
                product,
                amount: barcode.quantity,
            }
        },
        [catalogueData.products, locale]
    )

    const getProductsByBarcode = useCallback(
        (code) => {
            if (isNotNullOrUndefined(catalogueData.products)) {
                return Array.from(catalogueData.products.values())
                    .filter((product) => product.barcodes?.some((barcode) => barcode.code.includes(String(code))))
                    .map((product) => {
                        const barcode = product.barcodes.find((barcode) => barcode.code.includes(String(code))) || {}
                        return {
                            product,
                            amount: barcode.amount,
                        }
                    })
            }
            return []
        },
        [catalogueData.products, locale]
    )

    const getPromotionsForProductById = useCallback(
        (productId) =>
            catalogueData.promotions?.filter((promotion) =>
                promotion.products.some((product) => product.id === productId)
            ),
        [catalogueData.promotions, locale]
    )

    return (
        <CatalogueContext.Provider
            value={{
                isLoading,
                isError,
                catalogue,
                allCategoryGroupsInCatalogue,
                allProductsInCatalogue,
                allCategoriesInCatalogue,
                activeCategory: catalogueData.activeCategory,
                setActiveCategory: (cat) => setCatalogueData((prev) => ({ ...prev, activeCategory: cat })),
                activeCategoryGroup: catalogueData.activeCategoryGroup,
                setActiveCategoryGroup: (group) =>
                    setCatalogueData((prev) => ({ ...prev, activeCategoryGroup: group })),
                categoryScrollSpyIds: catalogueData.categoryScrollSpyIds,
                searchQuery,
                setSearchQuery,
                isSearching,
                setIsSearching,
                promotions: catalogueData.promotions,
                getCategoryGroupById,
                getCategoryById,
                getProductById,
                getProductByBarcode,
                getProductsByBarcode,
                getPromotionsForProductById,
            }}>
            {children}
        </CatalogueContext.Provider>
    )
}

export default CatalogueContextProvider
