import PromotionType from '../Enums/PromotionType.enum.ts'
import PromotionUnavailableType from '../Enums/PromotionUnavailableType.enum.ts'

class Promotion {
    constructor(promotion, productIds, products, theme) {
        const fallbackColor = theme.color

        const promotionProducts = this.getProductsForPromotion(productIds, products)

        let productDiscountPrices = []
        if (Array.isArray(promotion.rewards) && promotion.rewards.length > 0) {
            productDiscountPrices = this.calculateDiscountPrices(promotion.rewards[0].config, promotionProducts)
        }

        const checkAvailability = () => {
            if (promotionProducts.length === 0) {
                return {
                    status: false,
                    reason: PromotionUnavailableType.PRODUCTS_NOT_AVAILABLE,
                }
            }

            return {
                status: true,
                reason: null,
            }
        }

        this.id = promotion.id
        this.products = promotionProducts
        this.type = PromotionType[promotion.type] ?? PromotionType.OFFER
        this.name = promotion.name
        this.description = promotion.description
        this.promotionSourceId = promotion.promotion_source_id
        this.color = `#${promotion.color ?? fallbackColor ?? '000000'}`
        this.bannerImage = promotion.banner_image
        this.coverImage = promotion.cover_image
        this.promotionSource = promotion.promotion_source
        this.isTaDa = this.promotionSourceId === 1

        this.amountToAddToBasket = promotion.rules?.[0]?.config?.n ?? 1
        this.promotionPriceReduction = productDiscountPrices

        this.rules = promotion.rules
        this.rewards = promotion.rewards

        this.available = checkAvailability()
    }

    getProductsForPromotion(productIds, products) {
        const promotionProducts = []
        productIds.forEach((pId) => {
            const product = products?.get(parseInt(pId, 10))
            if (product) promotionProducts.push(product)
        })
        return promotionProducts
    }

    calculateDiscountPrices(rewardConfig, promoProducts) {
        const productDiscountPrices = []
        promoProducts.forEach((product) => {
            if (rewardConfig.is_percentage === true) {
                const originalPrice = product.price_int * rewardConfig.quantity
                const decimalDiscount = rewardConfig.value / 10000
                const discountedPrice = originalPrice - (originalPrice - originalPrice * decimalDiscount)
                productDiscountPrices.push({ id: product.id, value: discountedPrice })
                return
            }
            if (rewardConfig.is_percentage === false) {
                productDiscountPrices.push({ id: product.id, value: rewardConfig.value })
                return
            }
            productDiscountPrices.push({ id: product.id, value: 0 })
        })
        return productDiscountPrices
    }
}

export default Promotion
