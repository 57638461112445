import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import BillySDK from '../sdk/sdk'

interface ITranslationsOverride {
    [language: string]: Record<string, string>
}

function useTranslationOverride(eventId?: string): void {
    const { i18n } = useTranslation()
    const previousDataReference = useRef<ITranslationsOverride | null>(null)

    // Use React Query to fetch translations
    const { data } = useQuery({
        queryKey: ['translationsOverride', eventId],
        queryFn: async () => BillySDK.getTranslationsOverridesForEvent(eventId),
        enabled: isNotNullOrUndefined(eventId),
        staleTime: 30 * 60 * 1000, // 30 min cache, translations should not change very often
        refetchOnWindowFocus: false,
        retry: 0,
    })

    const overrideTranslations = useCallback(
        (overrides: ITranslationsOverride): void => {
            for (const lang of Object.keys(overrides)) {
                i18n.addResourceBundle(lang, 'overrides', overrides[lang], true, true)
            }
        },
        [i18n]
    )

    const removeOverrides = useCallback(
        (overrides: ITranslationsOverride | null): void => {
            if (!isNotNullOrUndefined(overrides)) return
            for (const lang of Object.keys(overrides)) {
                i18n.removeResourceBundle(lang, 'overrides')
            }
        },
        [i18n]
    )

    useEffect(() => {
        // Always remove previous overrides
        removeOverrides(previousDataReference.current)
        previousDataReference.current = null

        // set override when exist
        if (isNotNullOrUndefined(data)) {
            overrideTranslations(data)
            previousDataReference.current = data
        }
    }, [eventId, data, overrideTranslations, removeOverrides])
}

export default useTranslationOverride
