import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { isNotNullOrUndefined } from '../../structures/Guards/guards.utils'
import Button from '../01_atoms/Button/Button'
import Confetti from '../01_atoms/Confetti/Confetti'

import RatingButton from './RatingButton'

import './rating.scss'

interface IRatingProperties {
    onRating?: (data: { score: number | undefined; message: string | null }) => void
    onSubmit?: (data: { score: number | undefined; message: string | null }) => void
    placeId?: string | null
}

const Rating: FC<IRatingProperties> = ({ onRating, onSubmit, placeId = null }) => {
    const { t } = useTranslation()

    const [disabled, setDisabled] = useState(false)
    const [score, setScore] = useState<number | undefined>()
    const [message, setMessage] = useState<string | null>(null)

    const onScore = useCallback((givenScore: number) => {
        setScore(givenScore)
    }, [])

    const onSubmitRating = useCallback(() => {
        setDisabled(true)
        onSubmit?.({ score, message })
    }, [score, message, onSubmit])

    useEffect(() => {
        if (isNotNullOrUndefined(score)) {
            onRating?.({ score, message })
        }
    }, [score, message, onRating])

    const confettiComponent = useMemo(
        () =>
            isNotNullOrUndefined(score) && score >= 3 ? (
                <div className='success-explosion'>
                    <Confetti
                        y={1}
                        x={0.5}
                        particleCount={Math.abs(score) * 50}
                        launchSpeed={Math.abs(score) / 2}
                        key={score}
                    />
                </div>
            ) : null,
        [score]
    )

    return (
        <div className='c-rating'>
            <div className='c-rating-buttons'>
                {[1, 2, 3, 4, 5].map((value, index) => (
                    <Fragment key={value}>
                        <RatingButton
                            mood={value < 3 ? 'negative' : value === 3 ? 'neutral' : 'positive'}
                            imageId={String(Math.abs(2 - index))}
                            value={value}
                            active={score === value}
                            disabled={disabled}
                            onClick={onScore}
                        />
                        {score === value && value >= 3 ? confettiComponent : null}
                    </Fragment>
                ))}
            </div>

            {isNotNullOrUndefined(score) && (
                <div className='c-rating-input'>
                    {isNotNullOrUndefined(placeId) && score > 3 ? (
                        <div className='c-google-rating'>
                            <h3 className='c-google-rating-title'>{t('review.review_with_google.title')}</h3>
                            <p className='c-google-rating-body'>{t('review.review_with_google.body')}</p>
                            <a
                                className={classNames('c-button c-button-google')}
                                onClick={onSubmitRating}
                                target='_blank'
                                href={`https://search.google.com/local/writereview?placeid=${placeId}`}
                                rel='noreferrer'>
                                {t('review.button.review_with_google.cta')}
                            </a>
                        </div>
                    ) : (
                        <>
                            <textarea
                                autoFocus
                                maxLength={150}
                                value={message ?? ''}
                                onChange={(event) => {
                                    setMessage(event.target.value)
                                }}
                                className='c-textarea'
                                disabled={disabled}
                            />
                            <Button
                                disabled={disabled}
                                variant='dark'
                                label='Confirm'
                                onClick={onSubmitRating}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default Rating
