import { addTimeToCurrentDateTime } from '../../constants/helpers'
import TimeUnit from '../../structures/Enums/TimeUnit.enum.ts'
import { isNotNullOrUndefined } from '../../structures/Guards/guards.utils.ts'
import {
    ADD_PRODUCT,
    ADD_TIP,
    APPEND_ADDITIONS_TO_PRODUCT,
    CLOSE_BASKET,
    OPEN_BASKET,
    REMOVE_FROM_BASKET,
    REMOVE_PRODUCT,
    RESET_BASKET,
    UPDATE_BASKET,
    UPSELL_PRODUCT,
} from '../actions/basketActions'

const calculateTotalPrice = (products) =>
    Object.values(products).reduce((total, product) => {
        const totalProductPrice = (product.price_int + product.totalAdditionsPrice) * product.amount
        const promotionReduction =
            product.promotion?.promotionPriceReduction?.find((p) => p.id === parseInt(product.originalId, 10))?.value ??
            0
        return total + (totalProductPrice - promotionReduction)
    }, 0)

const calculateTotalLoyaltyPointsPrice = (products) =>
    Object.values(products).reduce(
        (total, product) => total + (product.loyalty_reward_credits_price ?? 0) * product.amount,
        0
    )

const calculateTotalAmount = (products) => Object.values(products).reduce((total, product) => total + product.amount, 0)

export default function basketReducer(state = { timestamp: Date.now() }, { type, payload }) {
    switch (type) {
        // Add product to basket
        // IF event is in basket, append to existing event. If product exists, add amount.
        // ELSE payload is put in event id slot.
        // cf. AddToBasketModal

        case ADD_PRODUCT:
            //* ******************************************* */
            //* ******************************************* */
            // if product has additions, add them to product id to split them in basket;
            payload.originalId = payload.id

            if (payload.has_additions) {
                let _id = String(payload.id)
                Object.keys(payload.additions).forEach((key) => {
                    _id = `${_id}-${key}`

                    payload.additions[key].values.forEach((item) => {
                        _id += String(item.id)
                    })
                })

                payload.id = _id
            }

            payload.id = String(payload.id)

            //* ******************************************* */
            //* ******************************************* */
            // if product is recommendation, add them to product id to split them in basket;

            if (payload.is_recommendation) {
                payload.id = String(`s-${payload.id}-${payload.parent_product_id}`)
            }

            //* ******************************************* */
            //* ******************************************* */
            // if product is cross_sell, add them to product id to split them in basket;

            if (payload.is_cross_sell) {
                payload.id = String(`x-${payload.type_reference_id}-${payload.id}`)
            }

            // if product has a promo, add it to product id to split them in basket;
            if (payload.promotion) {
                payload.id = String(`p-${payload.promotion.id}-${payload.id}-${payload.promotion_claim_id}`)
            }
            if (payload.promotion_reference_id && payload.promotion_claim_id) {
                payload.id = String(`${payload.id}-${payload.promotion_claim_id}`)
            }

            // if product has a loyaltypoints, add it to product id to split them in basket;
            if (payload.loyalty_reward_credits_price) {
                payload.id = String(`l-${payload.id}`)
            }

            // ******************************************** */
            // ******************************************** */
            // add to existing product ( update amount )
            // or make a new product entry in the basket
            if (state[payload.event_id]) {
                // state[payload.event_id].amounts[payload.originalId] += payload.amount;

                if (state[payload.event_id].products[payload.id]) {
                    payload.amount += state[payload.event_id].products[payload.id].amount
                    payload.addedAt = state[payload.event_id].products[payload.id].addedAt
                } else {
                    payload.addedAt = Date.now()
                }

                state[payload.event_id].products[payload.id] = payload
            } else {
                payload.addedAt = Date.now()
                state[payload.event_id] = {
                    products: { [payload.id]: payload },
                }
            }

            // update the state
            state = {
                ...state,
                timestamp: Date.now(),
                [payload.event_id]: {
                    eventName: payload.eventName,
                    eventId: payload.event_id,
                    groupId: payload.groupId,
                    totalPrice: calculateTotalPrice(state[payload.event_id].products),
                    totalLoyaltyPrice: calculateTotalLoyaltyPointsPrice(state[payload.event_id].products),
                    totalAmount: calculateTotalAmount(state[payload.event_id].products),
                    products: { ...state[payload.event_id].products },
                    expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
                },
            }

            return state

        // Update basket, when you change the amount in basket view
        // cf. QuantitySelectorModal

        case UPDATE_BASKET:
            if (state[payload.event_id]) {
                state[payload.event_id].products[payload.id] = payload
            }

            state = {
                ...state,
                timestamp: Date.now(),
                [payload.event_id]: {
                    ...state[payload.event_id],
                    ...{
                        totalPrice: calculateTotalPrice(state[payload.event_id].products),
                        totalLoyaltyPrice: calculateTotalLoyaltyPointsPrice(state[payload.event_id].products),
                        totalAmount: calculateTotalAmount(state[payload.event_id].products),
                        products: { ...state[payload.event_id].products },
                        expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
                    },
                },
            }

            return state

        case UPSELL_PRODUCT:
            if (state[payload.event_id] && state[payload.event_id].products) {
                const target = Object.values(state[payload.event_id].products).find(
                    (product) => product.originalId === payload.target_id
                )

                payload.originalId = payload.id
                payload.id = String(payload.id)
                payload.addedAt = isNotNullOrUndefined(target.addedAt) ? target.addedAt : Date.now()
                payload.upsell = 1
                payload.type = 'upsell'
                payload.type_reference_id = payload.upsell_rule_id

                if (payload.has_additions) {
                    let id = String(payload.id)

                    Object.keys(payload.additions).forEach((key) => {
                        id = `${id}-${key}`

                        payload.additions[key].values.forEach((item) => {
                            id += String(item.id)
                        })
                    })

                    payload.id = id
                }

                if (state[payload.event_id].products[payload.id]) {
                    payload.amount += state[payload.event_id].products[payload.id].amount
                }
                state[payload.event_id].products[payload.id] = payload

                delete state[payload.event_id].products[target.id]

                state = {
                    ...state,
                    timestamp: Date.now(),
                    [payload.event_id]: {
                        ...state[payload.event_id],
                        ...{
                            totalPrice: calculateTotalPrice(state[payload.event_id].products),
                            totalLoyaltyPrice: calculateTotalLoyaltyPointsPrice(state[payload.event_id].products),
                            totalAmount: calculateTotalAmount(state[payload.event_id].products),
                            products: { ...state[payload.event_id].products },
                            expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
                        },
                    },
                }
            }

            return state

        // Remove a product from basket
        // When the quantity of a product in basket becomes zero

        case REMOVE_PRODUCT:
            if (state[payload.event_id] && state[payload.event_id].products) {
                const currentBasketProducts = state[payload.event_id].products
                const promotionReferenceId =
                    state[payload.event_id].products[payload.id]?.promotion_reference_id ||
                    state[payload.event_id].products[payload.id]?.promotion?.id
                const promotionClaimId = state[payload.event_id].products[payload.id]?.promotion_claim_id

                if (isNotNullOrUndefined(promotionReferenceId) && isNotNullOrUndefined(promotionClaimId)) {
                    for (const productKey in currentBasketProducts) {
                        if (
                            currentBasketProducts[productKey].promotion_reference_id === promotionReferenceId &&
                            currentBasketProducts[productKey].promotion_claim_id === promotionClaimId &&
                            !isNotNullOrUndefined(currentBasketProducts[productKey].promotion)
                        ) {
                            delete currentBasketProducts[productKey].promotion_reference_id
                            delete currentBasketProducts[productKey].promotion_name
                            delete currentBasketProducts[productKey].promotion_claim_id
                            if (
                                state[payload.event_id].products[payload.id].id !== currentBasketProducts[productKey].id
                            ) {
                                const originalId = currentBasketProducts[productKey].originalId
                                currentBasketProducts[productKey].id = currentBasketProducts[productKey].originalId

                                if (isNotNullOrUndefined(state[payload.event_id].products[originalId])) {
                                    state[payload.event_id].products[originalId].amount +=
                                        currentBasketProducts[productKey].amount
                                } else {
                                    state[payload.event_id].products[originalId] = currentBasketProducts[productKey]
                                }

                                delete currentBasketProducts[productKey]
                            }
                        } else if (
                            currentBasketProducts[productKey].promotion?.id === promotionReferenceId &&
                            currentBasketProducts[productKey].promotion_claim_id === promotionClaimId
                        ) {
                            delete currentBasketProducts[productKey].promotion
                            delete currentBasketProducts[productKey].type
                            delete currentBasketProducts[productKey].type_reference_id
                            delete currentBasketProducts[productKey].promotion_name
                            delete currentBasketProducts[productKey].promotion_claim_id

                            if (
                                state[payload.event_id].products[payload.id].id !== currentBasketProducts[productKey].id
                            ) {
                                const originalId = currentBasketProducts[productKey].originalId
                                currentBasketProducts[productKey].id = currentBasketProducts[productKey].originalId

                                if (isNotNullOrUndefined(state[payload.event_id].products[originalId])) {
                                    state[payload.event_id].products[originalId].amount +=
                                        currentBasketProducts[productKey].amount
                                } else {
                                    state[payload.event_id].products[originalId] = currentBasketProducts[productKey]
                                }

                                delete currentBasketProducts[productKey]
                            }
                        }
                    }
                }

                delete state[payload.event_id].products[payload.id]

                state = {
                    ...state,
                    timestamp: Date.now(),
                    [payload.event_id]: {
                        ...state[payload.event_id],
                        ...{
                            products: { ...state[payload.event_id].products },
                            amounts: { ...state[payload.event_id].amounts },
                            totalPrice: calculateTotalPrice(state[payload.event_id].products),
                            totalLoyaltyPrice: calculateTotalLoyaltyPointsPrice(state[payload.event_id].products),
                            totalAmount: calculateTotalAmount(state[payload.event_id].products),
                            expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
                        },
                    },
                }
            }

            if (
                state[payload.event_id] &&
                state[payload.event_id].products &&
                Object.keys(state[payload.event_id]?.products ?? {}).length === 0
            ) {
                // delete event ID from state if products is empty & close basket
                delete state[payload.event_id]
                delete state.tips
            }

            return state

        // remove basket entirely for the event where they're on
        // when the user presses the trash button in the basket view.

        case REMOVE_FROM_BASKET:
            if (state[payload.event_id]) {
                state = { ...state }
                delete state[payload.event_id]
                delete state.tips
            }
            return state

        case RESET_BASKET:
            state = {}
            return state

        // Open & closed state of the basket
        // We sometimes need this info after a redirect from social logins e.g...

        case OPEN_BASKET:
            state = {
                ...state,
                isOpen: true,
            }
            return state

        case CLOSE_BASKET:
            state = {
                ...state,
                isOpen: false,
            }
            return state

        case ADD_TIP:
            state = {
                ...state,
                tips: {
                    ...state.tips,
                    [payload.id]: {
                        value: payload.tip,
                        type: payload.type,
                    },
                },
            }

            return state

        case APPEND_ADDITIONS_TO_PRODUCT:
            if (state[payload.event_id]) {
                //* ******************************************* */
                //* ******************************************* */
                // if product has additions, add them to product id to split them in basket;
                payload.originalId = payload.id
                payload.addedAt = Date.now()

                if (payload.has_additions) {
                    let id = String(payload.id)

                    Object.keys(payload.additions).forEach((key) => {
                        id = `${id}-${key}`

                        payload.additions[key].values.forEach((item) => {
                            id += String(item.id)
                        })
                    })

                    payload.id = id
                }

                payload.id = String(payload.id)

                // only change product to one with additions if any are selected
                if (payload.has_additions) {
                    state[payload.event_id].products[payload.id] = {
                        ...state[payload.event_id].products[payload.originalId],
                        ...payload,
                    }
                    delete state[payload.event_id].products[payload.originalId]
                }

                state = {
                    ...state,
                    [payload.event_id]: {
                        ...state[payload.event_id],
                        ...{
                            totalPrice: calculateTotalPrice(state[payload.event_id].products),
                            totalLoyaltyPrice: calculateTotalLoyaltyPointsPrice(state[payload.event_id].products),
                            totalAmount: calculateTotalAmount(state[payload.event_id].products),
                            products: { ...state[payload.event_id].products },
                            expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
                        },
                    },
                }
            }
            return state

        default:
            return state
    }
}
