import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EventGroupContext } from 'src/context/EventGroupContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

function useEventGroupBaskets() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { basket } = useSelector((state) => state) as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { eventGroup } = useContext(EventGroupContext) as any

    const basketsForOrderGroup = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const baskets: any = []
        if (isNotNullOrUndefined(eventGroup) && isNotNullOrUndefined(eventGroup?.events)) {
            for (const event of eventGroup.events) {
                if (isNotNullOrUndefined(basket[event.id])) {
                    baskets.push(basket[event.id])
                }
            }
        }

        return baskets
    }, [eventGroup, basket])

    return basketsForOrderGroup
}

export default useEventGroupBaskets
