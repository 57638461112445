/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from 'redux'
import { isDateExpired } from 'src/constants/helpers'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

// TODO: create basket interface & convert redux implementation to TS

interface ITableEntry {
    [key: string]: any
}

// eslint-disable-next-line unicorn/consistent-function-scoping
const filterExpiredTablesMiddleware: Middleware = () => (next) => (action) => {
    if (action.type === 'persist/REHYDRATE' && isNotNullOrUndefined(action.payload)) {
        const state: any = action.payload

        const filteredTables = Object.entries(state.tableNumbers).reduce(
            (accumulator: ITableEntry, [key, value]: [string, any]) => {
                if (isNotNullOrUndefined(value?.expiresAt)) {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (!isDateExpired(value.expiresAt ?? '')) {
                        accumulator[key] = value
                    }
                } else {
                    accumulator[key] = value
                }
                return accumulator
            },
            {}
        )

        action.payload.tableNumbers = filteredTables
    }
    return next(action)
}

export default filterExpiredTablesMiddleware
