import { useQuery } from '@tanstack/react-query'

import BillySDK from '../sdk/sdk'

import useTranslationOverride from './useTranslationOverride.ts'

function useEventQuery(eventId, params = null) {
    // force translation override
    useTranslationOverride(eventId)

    const {
        isLoading,
        error,
        isFetching,
        isError,
        data: event,
    } = useQuery({
        retry: 2,
        staleTime: 60 * 1000,
        refetchOnWindowFocus: true,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['event', { eventId }],
        enabled: !!eventId,
        queryFn: () => BillySDK.getEventById(eventId, params),
    })

    return {
        isLoading,
        error,
        isFetching,
        isError,
        event: event?.data,
    }
}

export default useEventQuery
