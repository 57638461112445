/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiGift, BiLockAlt } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import LoyaltyRewardProduct from 'src/components/01_atoms/LoyaltyRewardProduct/LoyaltyRewardProduct'
import MultiSwitch from 'src/components/01_atoms/MultiSwitch/MultiSwitch'
import Spinner from 'src/components/spinners/Spinner'
import { EventContext } from 'src/context/EventContextProvider'
import { EventGroupContext } from 'src/context/EventGroupContextProvider'
import { KioskContext } from 'src/context/KioskContextProvider'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import { useBasketCalculations } from 'src/hooks/useBasketCalculations'
import KioskMode from 'src/structures/Enums/KioskMode.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import ILoyaltyRewardProduct from 'src/structures/Interfaces/ILoyaltyRewardProduct'

import BillySDK from '../../../sdk/sdk'

import './loyaltyrewards.scss'

interface ILoyaltyRewardsProperties {
    venueId: string | undefined
}

const LoyaltyRewards: FC<ILoyaltyRewardsProperties> = ({ venueId }) => {
    const { t } = useTranslation()
    const { kioskMode } = useContext(KioskContext)

    const { event } = useContext(EventContext) as any
    const { eventGroup } = useContext(EventGroupContext) as any
    const { kiosk } = useSelector((state) => state) as any
    const { loyaltyContact } = useContext(LoyaltyContext)

    const { tokens } = useSelector((state) => state) as any
    const storedAccessToken = tokens?.access_token

    const { calculateSpendableLoyaltyPoints } = useBasketCalculations()
    const spendableLoyaltyPoints = useMemo(
        () =>
            calculateSpendableLoyaltyPoints({
                eventId: event?.id ?? null,
                eventGroupId: eventGroup?.id ?? null,
            }),
        [calculateSpendableLoyaltyPoints, event?.id, eventGroup?.id]
    )

    const rewardLabels = [
        { id: 1, name: 'All' },
        {
            id: 2,
            name: (
                <div className='reward-swith-icon'>
                    <BiGift />
                </div>
            ),
        },
        {
            id: 3,
            name: (
                <div className='reward-swith-icon'>
                    <BiLockAlt />
                </div>
            ),
        },
    ]

    const [activeCategory, setActiveCategory] = useState(0)

    const { data: loyaltyRewards, isLoading } = useQuery({
        retry: 1,
        staleTime: 0,
        refetchOnWindowFocus: true,
        select: (response) => response.data?.rewards as Array<ILoyaltyRewardProduct>,
        queryKey: ['loyaltyRewards', { id: venueId, token: storedAccessToken }],
        enabled: isNotNullOrUndefined(venueId) && isNotNullOrUndefined(storedAccessToken),
        queryFn: async () => BillySDK.loyaltyGetProgram(venueId),
    })

    const filteredLoyaltyRewards = useMemo(() => {
        if (activeCategory === 1)
            return loyaltyRewards?.filter(
                (reward) => reward.required_credits <= Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10)
            )
        if (activeCategory === 2)
            return loyaltyRewards?.filter(
                (reward) => reward.required_credits > Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10)
            )
        return loyaltyRewards
    }, [activeCategory, loyaltyRewards, loyaltyContact?.loyalty_balance])

    const onChangeActiveCategory = useCallback((index: number) => {
        setActiveCategory(index)
    }, [])

    if (
        !kioskMode ||
        (isNotNullOrUndefined(venueId) &&
            isNotNullOrUndefined(kiosk?.device?.config) &&
            !(kiosk?.device?.config?.mode === KioskMode.SELF_SCAN))
    ) {
        return (
            <div className='loyaltyrewards-rewards-container'>
                <h2 className='title'>{t('loyalty.rewards.title')}</h2>
                <div className='loyaltyrewards-rewards-selector'>
                    {isLoading ? (
                        <Spinner
                            staticPosition
                            style={{ minHeight: '200px' }}
                        />
                    ) : (
                        <>
                            <div className='loyaltyrewards-rewards-category-switch'>
                                <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className='loyaltyrewards-rewards-category-switch-inner'>
                                    <MultiSwitch
                                        active={activeCategory}
                                        items={rewardLabels}
                                        onChange={onChangeActiveCategory}
                                    />
                                </motion.div>
                            </div>
                            {isNotNullOrUndefined(loyaltyRewards) ? (
                                <motion.div
                                    key={activeCategory}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className='loyaltyrewards-rewards-content'>
                                    {filteredLoyaltyRewards?.map((reward: ILoyaltyRewardProduct) => (
                                        <LoyaltyRewardProduct
                                            key={reward.id}
                                            reward={reward}
                                            spendableLoyaltyPoints={spendableLoyaltyPoints}
                                        />
                                    ))}
                                </motion.div>
                            ) : (
                                <div className='loyaltyrewards-error'>
                                    <div className='loyaltyrewards-error-inner'>
                                        <svg
                                            className='error-icon'
                                            stroke='#EA5056'
                                            fill='#EA5056'
                                            strokeWidth='0'
                                            viewBox='0 0 24 24'
                                            width='100%'
                                            height='100%'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path d='M12 2l.642 .005l.616 .017l.299 .013l.579 .034l.553 .046c4.687 .455 6.65 2.333 7.166 6.906l.03 .29l.046 .553l.041 .727l.006 .15l.017 .617l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.455 4.687 -2.333 6.65 -6.906 7.166l-.29 .03l-.553 .046l-.727 .041l-.15 .006l-.617 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.687 -.455 -6.65 -2.333 -7.166 -6.906l-.03 -.29l-.046 -.553l-.041 -.727l-.006 -.15l-.017 -.617l-.004 -.318v-.648l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.455 -4.687 2.333 -6.65 6.906 -7.166l.29 -.03l.553 -.046l.727 -.041l.15 -.006l.617 -.017c.21 -.003 .424 -.005 .642 -.005zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z' />
                                        </svg>
                                        <span>Something went wrong!</span>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }

    return <div className='loyaltyrewards-rewards-placeholder' />
}

export default LoyaltyRewards
