import { useCallback } from 'react'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IServiceFee from 'src/structures/Interfaces/IServiceFee'

interface IApplicableFeesReturnType {
    totalApplicableFees: number
    filteredFees: Array<IServiceFee>
}

export const useServiceFees = () => {
    const getApplicableFees = useCallback((priceLimit: number, fees: Array<IServiceFee>): IApplicableFeesReturnType => {
        let totalCalculatedFees = 0
        const filteredFees: Array<IServiceFee> = []

        if (isNotNullOrUndefined(fees) && isNotNullOrUndefined(priceLimit)) {
            for (const feeItem of fees) {
                if (isNotNullOrUndefined(feeItem?.conditional_order_price_max_int)) {
                    if (priceLimit < feeItem.conditional_order_price_max_int) {
                        totalCalculatedFees += feeItem?.amount_int ?? 0
                        filteredFees.push(feeItem)
                    }
                } else {
                    totalCalculatedFees += feeItem?.amount_int ?? 0
                    filteredFees.push(feeItem)
                }
            }
        }

        return {
            totalApplicableFees: totalCalculatedFees,
            filteredFees,
        }
    }, [])

    return { getApplicableFees }
}
