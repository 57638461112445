import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Rating from '../../../components/Rating/Rating.tsx'
import { popIn } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'

import './modal.scss'

function RatingModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Use a ref to store the latest vote without triggering re-renders
    const voteRef = useRef({ score: -1, message: null })

    const onRating = useCallback((score) => {
        voteRef.current = score
    }, [])

    // when clicking google we submit on leave
    const onSubmit = useCallback(
        (score) => {
            voteRef.current = score
            dispatch(closeModal())
        },
        [dispatch, closeModal]
    )

    useEffect(
        () => () => {
            // Use the ref value on unmount
            BillySDK.rating(data.venueId, voteRef.current)
        },
        [data.venueId]
    )

    return (
        <motion.div
            className='c-modal--item'
            variants={popIn}
            initial='hidden'
            animate='visible'
            exit='exit'>
            <div
                style={{ position: 'relative' }}
                className='c-modal--item-content c-modal--rating'>
                <h3 className='c-modal--item-name'>{t('modal.rating.title', { venue: data?.venueName })}</h3>
                {data?.message && data.message()}

                <Rating
                    placeId={data.placeId}
                    onRating={onRating}
                    onSubmit={onSubmit}
                />
            </div>
        </motion.div>
    )
}

export default RatingModal
